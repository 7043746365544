import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useNavigation, useSearchParams } from "@remix-run/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/Button";
import { PageHeading, Paragraph } from "~/components/Typography";
import { graphql } from "~/graphql/gqlTada";

export interface CreateCaseFormData {
  email: string;
  firstName: string;
}

const fetchCaseByActivationPinDocument = graphql(`
  query fetchCaseByActivationPin($activationPin: Int!) {
    case_byActivationPin(activationPin: $activationPin) {
      id
      treatment {
        id
        name
        description
      }
      diagnosis {
        id
        name
        description
      }
      surgeryAt
      tenant {
        id
        name
        avatar
      }
    }
  }
`);

export const CaseActivateMutationDocument = graphql(`
  mutation CaseActivateMutation(
    $activationPin: Int!
    $data: CaseActivateInput!
  ) {
    case_activate(activationPin: $activationPin, data: $data) {
      id
      token
    }
  }
`);

export function ActivateCaseView() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const fetchCase = useQuery(fetchCaseByActivationPinDocument, {
    variables: {
      activationPin: parseInt(searchParams.get("activationPin") ?? ""),
    },
  });

  function handleClickCancel() {
    setSearchParams({});
  }

  const [activateCase, { loading }] = useMutation(CaseActivateMutationDocument);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateCaseFormData>();

  function handleSubmitCaseCreateForm(formData: CreateCaseFormData) {
    activateCase({
      variables: {
        data: { ...formData },
        activationPin: parseInt(searchParams.get("activationPin") ?? ""),
      },
    })
      .then(({ data }) => {
        navigate(`/validation?caseToken=${data?.case_activate?.token}`);
      })
      .catch(console.error);
  }

  const caseData = fetchCase.data?.case_byActivationPin;
  return (
    <div className=" absolute inset-2 top-4 shadow-xl bottom-0 p-4 bg-white rounded-t-xl z-40">
      <div>
        <div className="px-4 mt-8">
          <PageHeading>{t("activate-case.heading")}</PageHeading>

          <Paragraph>{t("activate-case.introduction")}</Paragraph>

          <div className="flex justify-center flex-col items-center my-6 gap-2">
            <img
              className="w-14 h-14 rounded-full bg-neutral-50"
              src={`/media/avatar/${caseData?.tenant?.avatar}`}
              alt={caseData?.tenant?.name}
            />
            <Paragraph>{caseData?.tenant?.name}</Paragraph>
          </div>

          <form
            onSubmit={handleSubmit(handleSubmitCaseCreateForm)}
            className="mb-8 mt-4 flex flex-col gap-2"
          >
            <div className="self-start flex justify-between w-full mt-6">
              <Button
                name="view"
                onClick={handleClickCancel}
                className="bg-red-400"
              >
                {t("label.abort")}
              </Button>
              <Button
                type="submit"
                disabled={!isValid}
                loading={loading || navigation.state !== "idle"}
              >
                {t("label.send")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
