import type { LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { getAuthAccount, isCase } from "~/libs/account/authentication";

import { useSearchParams } from "@remix-run/react";
import type { Variants } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedBackground } from "~/components/AnimatedBackground";
import { MobileConstraints } from "~/components/MobileConstraints";
import { ActivateCaseView } from "./ActivateCaseView";
import { LoginView } from "./LoginView";
import { ScanCodeView } from "./ScanCodeView";

export async function loader({ request }: LoaderFunctionArgs) {
  const account = await getAuthAccount(request);
  if (account) return redirect(`/my/cases`);
  if (await isCase(request)) return redirect(`/case`);

  return null;
}

export default function IndexPage() {
  const [searchParams] = useSearchParams();

  const variants: Variants = {
    fadeIn: {
      opacity: 1,
      filter: "blur(0px)",
      transition: { duration: 0.5, delay: 0.6 },
    },
    fadeOut: {
      opacity: 0,
      filter: "blur(40px)",
      transition: { duration: 0.5 },
    },
    slideUp: { top: "0dvh" },
    slideDown: { top: "100dvh" },
  };

  return (
    <MobileConstraints>
      <div className="absolute h-screen w-full">
        <AnimatedBackground />
        <div className="absolute inset-0 from-[rgba(0,0,0,0.2)] via-transparent to-[rgba(0,0,0,0.4)] bg-gradient-to-b" />
        {/* <ChangeLanguage /> */}
        <AnimatePresence initial={false} mode="sync">
          {!searchParams.get("view") && (
            <motion.div
              key="login"
              className="absolute inset-0"
              variants={variants}
              initial="fadeOut"
              animate="fadeIn"
              exit="fadeOut"
            >
              <LoginView />
            </motion.div>
          )}
          {(searchParams.get("activationPin") ||
            searchParams.get("view") === "scan") && (
            <motion.div
              key="scan"
              className="absolute inset-0"
              variants={variants}
              initial="fadeOut"
              animate="fadeIn"
              exit="fadeOut"
            >
              <ScanCodeView />
            </motion.div>
          )}
          {searchParams.get("view") === "activate" && (
            <motion.div
              key="login"
              className="absolute inset-0"
              variants={variants}
              initial="slideDown"
              animate="slideUp"
              exit="slideDown"
            >
              <ActivateCaseView />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </MobileConstraints>
  );
}
