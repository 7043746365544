import { motion } from "framer-motion";
import { Html5Qrcode } from "html5-qrcode";
import { memo, useEffect, useState } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import { ImSpinner8 } from "react-icons/im";

export interface QrScannerProps {
  onScanSuccessful: (scan: string) => void;
  onReady?: () => void;
}

export const QrCodeScanner = memo(({ onScanSuccessful }: QrScannerProps) => {
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode>();
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [scanResult, setScanResult] = useState<string | null>(null);

  function handleScanError() {}
  function handleScanSuccessful(scan: string) {
    setScanResult(scan);
  }

  async function handleClickScanner() {
    setLoading(true);
    try {
      await Html5Qrcode.getCameras();
      const html5QrCode = new Html5Qrcode("qrReader");
      await html5QrCode.start(
        { facingMode: "environment" },
        { fps: 20, aspectRatio: 1 },
        handleScanSuccessful,
        handleScanError
      );
      setHtml5QrCode(html5QrCode);
      setScanning(true);
      setLoading(false);
    } catch (error) {
      setScanning(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    navigator.permissions
      .query({ name: "camera" as PermissionName }) // Is really missing in type dev
      .then((permissionStatus) =>
        setBlocked(permissionStatus.state === "denied")
      )
      .catch(() => setBlocked(true));
    return () => {
      if (html5QrCode?.isScanning) {
        html5QrCode?.stop().catch(console.error);
      }
    };
  }, [html5QrCode]);

  useEffect(() => {
    if (scanResult?.length) {
      if (!scanning) return;
      onScanSuccessful(scanResult);
      setScanning(false);
      if (html5QrCode?.isScanning) {
        html5QrCode?.stop().catch(console.error);
      }
    }
  }, [html5QrCode, onScanSuccessful, scanResult, scanning]);

  if (blocked)
    return (
      <div className="relative flex aspect-square w-full items-center justify-center">
        <div className="absolute inset-0 overflow-hidden ">
          <div className="aspect-square bg-sky-900/20 flex items-center justify-center rounded-full p-14">
            Camera access denied <br /> please allow in your browser
          </div>
        </div>
      </div>
    );

  return (
    <div className="relative flex aspect-square w-full items-center justify-center">
      <div className="absolute inset-0 overflow-hidden rounded-lg">
        <div className="w-full" id="qrReader" />
      </div>

      {!scanning && (
        <motion.button
          whileTap={{ scale: 0.9 }}
          className="text-8xl relative z-10"
          onClick={handleClickScanner}
        >
          {loading ? (
            <ImSpinner8 className="animate-spin-loading" />
          ) : (
            <BsQrCodeScan />
          )}
        </motion.button>
      )}
    </div>
  );
});

QrCodeScanner.displayName = "QrCodeScanner";
