import { useLazyQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { graphql } from "~/graphql/gqlTada";
import { PinInput } from "./PinInput";
import { QrCodeScanner } from "./QrCodeScanner";

export const fetchCaseByActivationPinDocument = graphql(`
  query fetchCaseByActivationPin($activationPin: Int!) {
    case_byActivationPin(activationPin: $activationPin) {
      id
      token
    }
  }
`);

export function ScanCodeView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fetchCase] = useLazyQuery(fetchCaseByActivationPinDocument);
  const [invalidPin, setInvalidPin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pin, setPin] = useState(searchParams.get("activationPin") ?? "");

  function handleClickCancel() {
    searchParams.delete("view");
    setSearchParams({});
  }

  function handleScanSuccessful(scan: string) {
    const scanUrl = new URL(scan);
    const activationPin = scanUrl.searchParams.get("activationPin");
    if (activationPin?.length === 6) {
      handleChangePin(activationPin);
    }
  }

  function handleChangePin(value: string) {
    setPin(value);
    value.length === 6
      ? searchParams.set("activationPin", value)
      : searchParams.delete("activationPin");

    setSearchParams(searchParams);
  }

  useEffect(() => {
    const activationPin = searchParams.get("activationPin");
    if (activationPin?.length === 6)
      fetchCase({ variables: { activationPin: parseInt(activationPin) } })
        .then(({ data }) => {
          if (!data?.case_byActivationPin?.id) {
            setInvalidPin(true);
            setTimeout(() => {
              setPin("");
              searchParams.delete("activationPin");
              setSearchParams(searchParams);
              setInvalidPin(false);
            }, 2000);
          }
          if (data?.case_byActivationPin?.token) {
            navigate(
              `/validation?caseToken=${data?.case_byActivationPin?.token}`
            );
          } else if (data?.case_byActivationPin) {
            searchParams.set("view", "activate");
            setSearchParams(searchParams);
          }
        })
        .catch(console.error);
  }, [fetchCase, searchParams, setSearchParams, navigate]);

  return (
    <div className="text-center z-20 absolute inset-0 flex flex-col items-center justify-center text-white pb-32">
      <div className="relative w-full">
        <div className="flex items-center justify-center relative">
          <div className="w-56 aspect-square ">
            <QrCodeScanner onScanSuccessful={handleScanSuccessful} />
          </div>
        </div>
        <div className="absolute w-full px-6 text-sm font-medium drop-shadow-sm">
          <div className="mx-6 my-4 text-sm ">
            <span>{t("qr-code-scanner.intro")}</span>
          </div>
          <div className="mb-4 text-sm ">
            <span>{t("activate-case.enter-code")}</span>
          </div>

          <div className="mt-6">
            <div
              className={twMerge(
                invalidPin && "animate-shake",
                "flex flex-col items-center justify-center"
              )}
            >
              <PinInput
                error={invalidPin}
                numInputs={6}
                value={pin}
                onChange={handleChangePin}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-safe-offset-10">
        <button onClick={handleClickCancel} className="text-sm font-medium">
          {t("label.cancel")}
        </button>
      </div>
    </div>
  );
}
