import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";

interface PinInputProps {
  value: string;
  onChange: (value: string) => void;
  numInputs: number;
  error: boolean;
}

export const PinInput = (props: PinInputProps) => {
  const { t } = useTranslation();
  const classNames = {
    default:
      "!ring-0 w-12 border-r-0 border !outline-0 text-3xl font-medium !border-white first-of-type:rounded-l-md last-of-type:rounded-r-md last-of-type:border-r py-3 text-center bg-white bg-opacity-40 text-white ",
    error:
      "!ring-0 w-12 border-r-0 border !outline-0 text-3xl font-medium !border-red-500 first-of-type:rounded-l-md last-of-type:rounded-r-md last-of-type:border-r py-3 text-center bg-red-200 bg-opacity-40 text-red-500 ",
  };
  return (
    <>
      <OtpInput
        {...props}
        renderInput={(inputProps) => (
          <input
            {...inputProps}
            style={{}}
            maxLength={1}
            inputMode="numeric"
            className={classNames[props.error ? "error" : "default"]}
          />
        )}
      />
      {props.error && (
        <div className="bg-red-500 px-2 py-1 text-white text-sm mt-2">
          {t("error-message.invalid-pin")}
        </div>
      )}
    </>
  );
};

PinInput.displayName = "PinInput";
