import { Link, useSearchParams } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import logo from "~/assets/rehaber-logo.svg";

export function LoginView() {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();

  function handleClickActivateCaseButton() {
    setSearchParams({ view: "scan" });
  }

  return (
    <>
      <div className="text-center z-10 absolute inset-0 flex flex-col items-center justify-center">
        <img className="w-1/2 m-auto invert" src={logo} alt="" />
      </div>
      <div className="absolute bottom-0 h-1/2 inset-x-0 p-6  bg-opacity-70 z-20 flex flex-col justify-center">
        <div className="text-center">
          <button
            className="rounded-md bg-white text-sky-500 px-3 py-2.5 text-sm font-medium  inline-flex items-center gap-2"
            onClick={handleClickActivateCaseButton}
          >
            {t("label.activate-case-now")}
          </button>
        </div>
        <footer className=" opacity-80 absolute inset-6 top-auto flex justify-between text-sm mt-12 text-white">
          <Link to="/login">{t("label.login")}</Link>
          <div className="flex items-center gap-4 ">
            <Link to="/privacy">{t("footer-link.privacy.text")}</Link>
            <Link to="/imprint">{t("footer-link.imprint.text")}</Link>
          </div>
        </footer>
      </div>
    </>
  );
}
